<template>
  <el-dialog
      :title="$t('system.log_your_time')"
      :visible="isVisible"
      @close="onClose"
      center
      destroy-on-close
  >
    <el-form
        class="lb-m-0 lb_top w-100"
        label-position="top"
        label-width="140px"
    >
      <div class="row">
        <div class="col-md-6">
          <el-form-item
              :label="$t('time_entry.hours')"
              :size="fieldsSize"
              required
          >
            <el-input
                :class="checkinputRequired('timeSpent') ? 'validate-error' : ''"
                :size="fieldsSize"
                name="timeSpent"
                placeholder="000:00"
                type="text"
                v-mask="'###:##'"
                v-model="form.timeSpent"
            />
            <small
                class="help-block"
                v-if="backend_errors.timeSpent && !checkinputRequired('timeSpent')"
            >
              <span v-html="validationTranslate(backend_errors.timeSpent[0])"></span>
            </small>
          </el-form-item>
        </div>
        <div class="col-6">
          <el-form-item
              :label="$t('time_entry.activity')"
              :size="fieldsSize"
              required
          >
            <el-select
                :class="checkinputRequired('activityId') ? 'validate-error' : ''"
                :placeholder="$t('time_entry.activity')"
                :size="fieldsSize"
                filterable
                name="activityId"
                style="width: 100%;"
                v-model="form.activityId"
            >
              <el-option
                  :key="value.id"
                  :label="value.name"
                  :value="value.id"
                  v-for="value in activities"
              >
              </el-option>
            </el-select>
            <small
                class="help-block"
                v-if="backend_errors.activityId && !checkinputRequired('activityId')"
            >
              <span v-html="validationTranslate(backend_errors.activityId[0])" />
            </small>
          </el-form-item>
        </div>
        <div class="col-12">
          <el-form-item
              :label="$t('time_entry.comments')"
              :size="fieldsSize"
              required
          >
            <el-input
                :class="checkinputRequired('comments') ? 'validate-error' : ''"
                :size="fieldsSize"
                @keyup.ctrl.enter.native="save"
                name="comments"
                resize="none"
                rows="5"
                type="textarea"
                v-model="form.comments"
            >
            </el-input>
            <small
                class="help-block"
                v-if="backend_errors.comments && !checkinputRequired('comments')"
            >
              <span v-html="validationTranslate(backend_errors.comments[0])"></span>
            </small>
          </el-form-item>
        </div>
        <div class="col-12">
          <el-form-item class="m-0 ptx-20 d-flex justify-content-end">
            <el-button
                :loading="loading"
                :size="fieldsSize"
                @click.prevent.stop="save"
                type="primary"
            >
              {{ $t('system.save') }}
            </el-button>
            <el-button
                :size="fieldsSize"
                @click="onClose"
            >
              {{ $t('system.cancel') }}
            </el-button>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import axios from 'axios';
import time from '../../mixin/time';

export default {
  name: 'LogTimeDialog',
  mixins: [time],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    backend_errors: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loggedSeconds: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      fieldsSize: 'small',
      inputRequiredEmpty: [],
      form: {
        timeSpent: '',
        activityId: null,
        comments: '',
      },
      activities: [],
    };
  },
  watch: {
    isVisible(newVal) {
      if (newVal) {
        let activityId = null;
        if (Array.isArray(this.activities) && this.activities.length) {
          const defaultValue = this.activities.find(item => item.is_default === 1);
          if (defaultValue) {
            activityId = defaultValue.id;
          }
        }
        this.form = {
          timeSpent: '',
          activityId,
          comments: '',
        };
      }
    },
    loggedSeconds(newVal) {
      this.form.timeSpent = this.secondsToHHHmm(newVal);
    },
  },
  beforeMount() {
    this.fetchActivities();
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    save() {
      const requiredInputs = [];
      if (this.form.timeSpent === '000:00') {
        requiredInputs.push('timeSpent');
      }
      this.checkValidation(requiredInputs).then(() => this.$emit('save', this.form));
    },
    fetchActivities() {
      axios.get('enumeration?type=time_entry_activity').then(response => {
        this.activities = response.data.data;
      });
    },
  },
};
</script>

<style scoped>

</style>
